import React from "react";
import Image from "@/components/Image/Image";
import clsx from "clsx";

const MainImage = ({ image, description }) => {
  const { width, height } = image?.childImageSharp?.gatsbyImageData;
  const resolution = width > height ? "max-w-[1350px]" : "max-w-[850px]";

  return (
    <div className={clsx(resolution, "mx-auto")}>
      <Image src={image} alt={description} />
    </div>
  );
};

export default MainImage;
