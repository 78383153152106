import React from "react";
import { Link } from "gatsby";
import MainImage from "@/components/MainImage/MainImage";
import Layout from "@/components/Layout/Layout";

const MainTemplate = ({ pageContext }) => {
  const { content } = pageContext || {};

  return (
    <Layout title="Home">
      <ul className="w-full py-8 px-4 md:px-0 md:py-28">
        {content &&
          content.map((nft) => (
            <li
              key={nft.name}
              className="w-full mx-auto max-w-[1350px] mb-8 md:mb-[55px]"
            >
              <Link to={nft.name}>
                <MainImage {...nft.cover} />
              </Link>
            </li>
          ))}
      </ul>
    </Layout>
  );
};

export default MainTemplate;
